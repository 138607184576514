import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IS_SERVER } from '@lib/tokens';
import { IconRegistry } from '@lib/icon-registry';

@Component({
  standalone: true,
  selector: 'dsp-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input() icon = 'info';

  constructor(
    @Inject(IS_SERVER) private isServer: boolean,
    private el: ElementRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.isServer) {
      return;
    }

    if (changes.icon?.currentValue) {
      this.el.nativeElement.style.setProperty('--icon', IconRegistry.getIcon(changes.icon.currentValue));
      this.cd.markForCheck();
    }
  }
}
